import api from './api';
import { httpBuildQuery } from '@/lib/functions';

class UserService {
  getAll(data) {
    const params = data ? '?'+httpBuildQuery(data) : '';

    return api
      .get('/user'+params)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  get(username) {
    return api
      .get('/user/'+username)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  create(data) {
    return api
      .post('/user', data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  update(username, data) {
    return api
      .put('/user/'+username, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  block(username) {
    return api
      .post('/user/'+username+'/block')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  delete(username) {
    return api
      .delete('/user/'+username)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  batchUpdate(data) {
    return api
      .post('/user/batch-update', data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  batchActivate(data) {
    return api
      .post('/user/batch-activate', data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  batchBlock(data) {
    return api
      .post('/user/batch-block', data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  batchDelete(data) {
    return api
      .post('/user/batch-delete', data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  export(data) {
    const params = data ? '?'+httpBuildQuery(data) : '';
    return api
      .get('/user/export'+params)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  getLeadcoin(username) {
    return api
      .get('/user/'+username+'/leadcoin')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  addLeadcoin(username, data) {
    return api
      .post('/user/'+username+'/add-leadcoin', data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  getLeadcoinHistory(id) {
    return api
      .get('/user/'+id+'/leadcoin-history')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  getData() {
    return api
      .get('/user/get-data')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  access(username, data) {
    return api
      .post('/user/'+username+'/access', data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }
}

export default new UserService();
