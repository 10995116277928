//import 'ag-grid-community/dist/styles/ag-grid.css';
import 'v-calendar/dist/style.css';
import 'vue3-colorpicker/style.css';
import '@/assets/scss/style.scss';
import { createApp } from 'vue';
import Inputmask from 'inputmask';
import Notifications from '@kyvg/vue3-notification';
import moment from 'moment';
import VCalendar from 'v-calendar';
import FloatingVue from 'floating-vue';
import Vue3ColorPicker from 'vue3-colorpicker';
import App from './App.vue';
import router from './router';
import store from './store';
import setupInterceptors from './services/setupInterceptors';

setupInterceptors(store);

const app = createApp(App);

app.use(Notifications);
app.use(VCalendar, {
  locales: {
    'ru-RU': {
      mode: 'date',
      firstDayOfWeek: 2,
      masks: {
        input: 'DD.MM.YYYY',
        data: 'DD.MM.YYYY',
        weekdays: 'WW',
      },
    },
  },
});
app.use(FloatingVue);
app.use(Vue3ColorPicker);
app.use(router);
app.use(store);
app.mount('#app');


// =============================================================================

// inputmask
Inputmask.extendDefaults({
  showMaskOnHover: false,
  //autoUnmask: true,
});
Inputmask.extendAliases({
  phone: {
    mask: '+7 (999) 999-99-99',
    placeholder: '_',
    onBeforeMask: function (value) {
      value = value.replace(/[^\d;]/g,'');
      if (value.length == 11 && (value.substr(0,1) == 8 || value.substr(0,1) == 7)) value = value.substr(1);
      return value;
    },
  },
  date: {
    alias: 'datetime',
    inputFormat: 'dd.mm.yyyy',
    placeholder: 'дд.мм.гггг',
  },
});
app.directive('inputmask', {
  beforeMount(el, binding) {
    function triggerInput() {
      const event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      this.dispatchEvent(event);
    }
    Inputmask(binding.value).mask(el);
    el.addEventListener('paste', triggerInput);
    el.addEventListener('cut', triggerInput);
  },
});

// Клик вне элемента
app.directive('click-outside', {
  beforeMount: function (el, binding) {
    // Define ourClickEventHandler
    const ourClickEventHandler = event => {
      if (!el.contains(event.target) && el !== event.target) {
        // as we are attaching an click event listern to the document (below)
        // ensure the events target is outside the element or a child of it
        binding.value(event); // before binding it
      }
    };
    // attached the handler to the element so we can remove it later easily
    el.__vueClickEventHandler__ = ourClickEventHandler;

    // attaching ourClickEventHandler to a listener on the document here
    document.addEventListener('click', ourClickEventHandler);
  },
  unmounted: function (el) {
    // Remove Event Listener
    document.removeEventListener('click', el.__vueClickEventHandler__);
  },
});

// Преобразование timestamp в дату и время
app.config.globalProperties.$timeFormat = (timestamp, format) => {
  return (timestamp && format) ? moment(timestamp*1000).utcOffset(3).format(format) : '';
};

// Слонение слова
app.config.globalProperties.$declination = (n, form) => {
  n = Math.abs(n)%100;
  if (n >= 5 && n <= 20) return form[2];
  n %= 10;
  if (n == 1) return form[0];
  if( n >= 2 && n <= 4) return form[1];
  return form[2];
}

// Проверка разрешений
function can(permissions) {
  const userPermissions = store.state.auth.user.permissions;
  let can = false;
  for (let i = 0; i < permissions.length; i++) {
    if (userPermissions.indexOf(permissions[i]) > -1) {
      can = true;
      break;
    }
  }
  return can;
}
app.directive('can', (el, binding, vnode) => vnode.el.hidden = !can(binding.value));
app.config.globalProperties.$can = permissions => can(permissions);
app.config.globalProperties.$can0 = permissions => can(permissions);
